<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-row>
      <v-col
        cols="4"
      >
      {{ storeInfoText }}
      </v-col>
      <v-col
        cols="1"
        style="flex:0 0 3.3333333333%"
      >
      <v-img width="30" height="30" :src="iconUrl"/>
      </v-col>
      <v-col
        cols="2"
      >
        {{ weatherInfoText }}
      </v-col>
    </v-row>
    <v-row>
      <!-- Influencing The Influencer -->
      <v-col
        v-for="(facadeCameraInfo,index) in userData.facadeCameraData"
        :key="facadeCameraInfo.id"
        md="4"
        sm="6"
        style="max-width:320px"
        cols="12"
      >
        <v-card v-if="!loading">
          <v-card-title>
            カメラ{{ index+1 }}
          </v-card-title>
          <v-card-text>
            {{ facadeCameraInfo.position }}
          </v-card-text>
          <div @click="imageClick(facadeCameraInfo)" :style="{backgroundImage: 'url(' + url+facadeCameraInfo.nowFacadeImageUrl + ')'}" class="justifiy-content-center">
            <v-img
              :src="require(`@/assets${facadeCameraInfo.overlayUrl}`)"
              ></v-img>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense
        :style="`width:${widthSize*100}px`"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  style="width:100px"
                  :rowspan="userData.searchType === 1 ? '3': '2'"
                >
                  時間帯
                </th>
                <template v-for="(item, index) in userData.head1List">
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                    style="width:100px"
                    rowspan="2"
                  >
                    {{ item }}
                  </th>
                </template>
                <template v-for="(item, index) in userData.head2List">
                  <th
                    v-if="userData.searchType === 1"
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                    style="width:100px"
                    rowspan="2"
                  >
                    {{ item }} <br>客数<br>（本日）
                  </th>
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                    style="width:100px"
                    rowspan="2"
                  >
                    {{ item }} <br>客数<br>（前週同曜日）
                  </th>
                </template>
                <template v-for="(item, index) in userData.head3List">
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                    colspan="2"
                  >
                    {{ item }} 客数
                  </th>
                </template>
              </tr>
              <tr>
                <template v-for="(item, index) in userData.head3List">
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                    style="width:100px"
                  >
                    昨年<br>同曜日
                  </th>
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                    style="width:100px"
                  >
                    昨年<br>同日
                  </th>
                </template>
              </tr>
              <tr v-if="userData.searchType === 1">
                <template v-for="(item, index) in userData.head1List">
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                  >
                    {{ userData.searchDate }}
                  </th>
                </template>
                <template v-for="(item, index) in userData.head2List">
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                  >
                    {{ userData.todayDate }}
                  </th>
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                  >
                    {{ userData.lastWeekDate }}
                  </th>
                </template>
                <template v-for="(item, index) in userData.head3List">
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                  >
                    {{ userData.lastYearSameWeek }}
                  </th>
                  <th
                    :key="`${index}${Math.random().toString(32)}`"
                    class="text-center"
                  >
                    {{ userData.lastYear }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.tableData"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td
                  v-if="isKeyExists(item.column1)"
                  :class="getColumnClass(index,'column1')"
                >
                  {{ item.column1.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column2)"
                  :class="getColumnClass(index,'column2')"
                >
                  {{ item.column2.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column3)"
                  :class="getColumnClass(index,'column3')"
                >
                  {{ item.column3.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column4)"
                  :class="getColumnClass(index,'column4')"
                >
                  {{ item.column4.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column5)"
                  :class="getColumnClass(index,'column5')"
                >
                  {{ item.column5.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column6)"
                  :class="getColumnClass(index,'column6')"
                >
                  {{ item.column6.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column7)"
                  :class="getColumnClass(index,'column7')"
                >
                  {{ item.column7.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column8)"
                  :class="getColumnClass(index,'column8')"
                >
                  {{ item.column8.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column9)"
                  :class="getColumnClass(index,'column9')"
                >
                  {{ item.column9.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column10)"
                  :class="getColumnClass(index,'column10')"
                >
                  {{ item.column10.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column11)"
                  :class="getColumnClass(index,'column11')"
                >
                  {{ item.column11.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column12)"
                  :class="getColumnClass(index,'column12')"
                >
                  {{ item.column12.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column13)"
                  :class="getColumnClass(index,'column13')"
                >
                  {{ item.column13.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column14)"
                  :class="getColumnClass(index,'column14')"
                >
                  {{ item.column14.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column15)"
                  :class="getColumnClass(index,'column15')"
                >
                  {{ item.column15.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column16)"
                  :class="getColumnClass(index,'column16')"
                >
                  {{ item.column16.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column17)"
                  :class="getColumnClass(index,'column17')"
                >
                  {{ item.column17.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column18)"
                  :class="getColumnClass(index,'column18')"
                >
                  {{ item.column18.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column19)"
                  :class="getColumnClass(index,'column19')"
                >
                  {{ item.column19.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column20)"
                  :class="getColumnClass(index,'column20')"
                >
                  {{ item.column20.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column21)"
                  :class="getColumnClass(index,'column21')"
                >
                  {{ item.column21.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column22)"
                  :class="getColumnClass(index,'column22')"
                >
                  {{ item.column22.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column23)"
                  :class="getColumnClass(index,'column23')"
                >
                  {{ item.column23.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column24)"
                  :class="getColumnClass(index,'column24')"
                >
                  {{ item.column24.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column25)"
                  :class="getColumnClass(index,'column25')"
                >
                  {{ item.column25.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column26)"
                  :class="getColumnClass(index,'column26')"
                >
                  {{ item.column26.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column27)"
                  :class="getColumnClass(index,'column27')"
                >
                  {{ item.column27.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column28)"
                  :class="getColumnClass(index,'column28')"
                >
                  {{ item.column28.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column29)"
                  :class="getColumnClass(index,'column29')"
                >
                  {{ item.column29.facilityValue }}
                </td>
                <td
                  v-if="isKeyExists(item.column30)"
                  :class="getColumnClass(index,'column30')"
                >
                  {{ item.column30.facilityValue }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                :key="tableFooterData.time"
                class="yellow"
              >
                <td class="text-center">
                  {{ tableFooterData.time }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column1)"
                  class="text-right"
                >
                  {{ tableFooterData.column1 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column2)"
                  class="text-right"
                >
                  {{ tableFooterData.column2 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column3)"
                  class="text-right"
                >
                  {{ tableFooterData.column3 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column4)"
                  class="text-right"
                >
                  {{ tableFooterData.column4 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column5)"
                  class="text-right"
                >
                  {{ tableFooterData.column5 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column6)"
                  class="text-right"
                >
                  {{ tableFooterData.column6 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column7)"
                  class="text-right"
                >
                  {{ tableFooterData.column7 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column8)"
                  class="text-right"
                >
                  {{ tableFooterData.column8 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column9)"
                  class="text-right"
                >
                  {{ tableFooterData.column9 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column10)"
                  class="text-right"
                >
                  {{ tableFooterData.column10 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column11)"
                  class="text-right"
                >
                  {{ tableFooterData.column11 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column12)"
                  class="text-right"
                >
                  {{ tableFooterData.column12 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column13)"
                  class="text-right"
                >
                  {{ tableFooterData.column13 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column14)"
                  class="text-right"
                >
                  {{ tableFooterData.column14 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column15)"
                  class="text-right"
                >
                  {{ tableFooterData.column15 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column16)"
                  class="text-right"
                >
                  {{ tableFooterData.column16 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column17)"
                  class="text-right"
                >
                  {{ tableFooterData.column17 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column18)"
                  class="text-right"
                >
                  {{ tableFooterData.column18 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column19)"
                  class="text-right"
                >
                  {{ tableFooterData.column19 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column20)"
                  class="text-right"
                >
                  {{ tableFooterData.column20 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column21)"
                  class="text-right"
                >
                  {{ tableFooterData.column21 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column22)"
                  class="text-right"
                >
                  {{ tableFooterData.column22 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column23)"
                  class="text-right"
                >
                  {{ tableFooterData.column23 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column24)"
                  class="text-right"
                >
                  {{ tableFooterData.column24 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column25)"
                  class="text-right"
                >
                  {{ tableFooterData.column25 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column26)"
                  class="text-right"
                >
                  {{ tableFooterData.column26 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column27)"
                  class="text-right"
                >
                  {{ tableFooterData.column27 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column28)"
                  class="text-right"
                >
                  {{ tableFooterData.column28 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column29)"
                  class="text-right"
                >
                  {{ tableFooterData.column29 }}
                </td>
                <td
                  v-if="!isEmpty(tableFooterData.column30)"
                  class="text-right"
                >
                  {{ tableFooterData.column30 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>

    </v-row>
    <v-row>
      <div id="mainTable"
        :data-cols-width="widthArray"
        hidden>
        <v-col>
          <table><tr></tr></table>
          <v-simple-table dense
          :style="`width:${widthSize*100}px`"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    style="width:100px"
                    data-b-a-s="thin"
                    data-a-h="center"
                    data-fill-color="ffccff"
                    :rowspan="userData.searchType === 1 ? '3': '2'"
                  >
                    時間帯
                  </th>
                  <template v-for="(item, index) in userData.head1List">
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      style="width:100px"
                      data-fill-color="ffccff"
                      data-b-a-s="thin"
                      data-a-h="center"
                      rowspan="2"
                    >
                      {{ item }}
                    </th>
                  </template>
                  <template v-for="(item, index) in userData.head2List">
                    <th
                      v-if="userData.searchType === 1"
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      style="width:100px"
                      data-fill-color="ffccff"
                      data-b-a-s="thin"
                      data-a-h="center"
                      rowspan="2"
                    >
                      {{ item }} <br>客数<br>（本日）
                    </th>
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      style="width:100px"
                      data-fill-color="ffccff"
                      data-b-a-s="thin"
                      data-a-h="center"
                      rowspan="2"
                    >
                      {{ item }} <br>客数<br>（前週同曜日）
                    </th>
                  </template>
                  <template v-for="(item, index) in userData.head3List">
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                      colspan="2"
                    >
                      {{ item }} 客数
                    </th>
                  </template>
                </tr>
                <tr>
                  <template v-for="(item, index) in userData.head3List">
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      style="width:100px"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      昨年<br>同曜日
                    </th>
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      style="width:100px"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      昨年<br>同日
                    </th>
                  </template>
                </tr>
                <tr v-if="userData.searchType === 1">
                  <template v-for="(item, index) in userData.head1List">
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      {{ userData.searchDate }}
                    </th>
                  </template>
                  <template v-for="(item, index) in userData.head2List">
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      {{ userData.todayDate }}
                    </th>
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      {{ userData.lastWeekDate }}
                    </th>
                  </template>
                  <template v-for="(item, index) in userData.head3List">
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      {{ userData.lastYearSameWeek }}
                    </th>
                    <th
                      :key="`${index}${Math.random().toString(32)}`"
                      class="text-center"
                      data-b-a-s="thin"
                      data-a-h="center"
                      data-fill-color="ffccff"
                    >
                      {{ userData.lastYear }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.tableData"
                  :key="item.time"
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="getColor(item.time, index)"
                >
                  <td class="text-center"
                   data-b-a-s="thin"
                   data-a-h="center"
                   :data-fill-color="getColor(item.time, index)"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column1)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column1.facilityValue === 0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column1')"
                  >
                    {{ String(item.column1.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column2)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column2.facilityValue === 0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column2')"
                  >
                    {{ String(item.column2.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column3)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column3.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column3')"
                  >
                    {{ String(item.column3.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column4)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column4.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column4')"
                  >
                    {{ String(item.column4.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column5)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column5.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column5')"
                  >
                    {{ String(item.column5.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column6)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column6.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column6')"
                  >
                    {{ String(item.column6.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column7)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column7.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column7')"
                  >
                    {{ String(item.column7.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column8)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column8.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column8')"
                  >
                    {{ String(item.column8.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column9)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column9.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column9')"
                  >
                    {{ String(item.column9.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column10)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column10.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column10')"
                  >
                    {{ String(item.column10.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column11)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column11.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column11')"
                  >
                    {{ String(item.column11.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column12)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column12.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column12')"
                  >
                    {{ String(item.column12.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column13)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column13.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column13')"
                  >
                    {{ String(item.column13.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column14)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column14.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column14')"
                  >
                    {{ String(item.column14.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column15)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column15.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column15')"
                  >
                    {{ String(item.column15.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column16)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column16.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column16')"
                  >
                    {{ String(item.column16.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column17)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column17.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column17')"
                  >
                    {{ String(item.column17.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column18)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column18.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column18')"
                  >
                    {{ String(item.column18.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column19)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column19.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column19')"
                  >
                    {{ String(item.column19.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column20)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column20.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column20')"
                  >
                    {{ String(item.column20.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column21)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column21.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column21')"
                  >
                    {{ String(item.column21.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column22)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column22.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column22')"
                  >
                    {{ String(item.column22.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column23)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column23.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column23')"
                  >
                    {{ String(item.column23.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column24)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column24.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column24')"
                  >
                    {{ String(item.column24.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column25)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column25.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column25')"
                  >
                    {{ String(item.column25.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column26)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column26.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column26')"
                  >
                    {{ String(item.column26.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column27)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column27.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column27')"
                  >
                    {{ String(item.column27.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column28)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column28.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column28')"
                  >
                    {{ String(item.column28.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column29)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column29.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column29')"
                  >
                    {{ String(item.column29.facilityValue).replace(',','').replace(',','') }}
                  </td>
                  <td
                    v-if="isKeyExists(item.column30)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="item.column30.facilityValue ===  0 ? 0:'###,###'"
                    :data-fill-color="getColumnColor(item.time, index,'column30')"
                  >
                    {{ String(item.column30.facilityValue).replace(',','').replace(',','') }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr
                  :key="tableFooterData.time"
                  class="yellow"
                >
                  <td
                    data-b-a-s="thin"
                    data-a-h="center"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.time }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column1)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column1 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column1 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column2)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column2 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column2 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column3)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column3 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column3 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column4)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column4 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column4 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column5)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column5 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column5 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column6)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column6 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column6 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column7)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column7 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column7 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column8)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column8 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column8 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column9)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column9 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column9 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column10)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column10 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column10 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column11)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column11 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column11 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column12)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column12 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column12 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column13)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column13 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column13 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column14)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column14 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column14 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column15)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column15 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column15 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column16)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column16 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column16 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column17)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column17 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column17 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column18)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column18 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column18 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column19)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column19 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column19 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column20)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column20 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column20 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column21)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column21 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column21 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column22)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column22 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column22 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column23)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column23 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column23 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column24)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column24 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column24 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column25)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column25 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column25 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column26)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column26 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column26 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column27)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column27 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column27 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column28)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column28 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column28 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column29)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column29 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column29 }}
                  </td>
                  <td
                    v-if="!isEmpty(tableFooterData.column30)"
                    data-b-a-s="thin"
                    data-t="n"
                    :data-num-fmt="tableFooterData.column30 === 0 ? 0:'###,###'"
                    data-fill-color="ffff88"
                  >
                    {{ tableFooterData.column30 }}
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-col>
      </div>
      <v-col>
        <v-btn
          v-if="!loading"
          color="primary"
          dark
          style="text-transform: none"
          @click="generateXLSX"
        >
          Excel Download
          <v-icon
            dark
            right
          >
            {{ icons.mdiDownloadOutline }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '400px'}"
      ></div>
    </v-row>
  </div>
</template>

<script>
import eventBus from '@core/utils/eventBus'
import store from '@/store'
import enterShopStoreModule from './enterShopStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
import { isEmpty, dateFormattedJP, date2yyyymmddhhmmss } from '@core/utils'
import Loading from '@/views/components/load/Loading.vue'
import tableToExcelJs from '@linways/table-to-excel'
import 'echarts/lib/chart/line'
import { mdiDownloadOutline } from '@mdi/js'

const echarts = require('echarts')

const USER_APP_STORE_MODULE_NAME = 'app-enterShop'
const currentDate = new Date()
const userData = ref([])

export default {
  components: { Loading },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
      }, 500)
    },
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('enter-ShopSearch')
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')

    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    // 日付選択コンポーネントを非表示させる
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false

    // 拠点選択コンポーネントを非表示させる
    this.$selectScDisplayFlag = false

    this.$domesticDatePickerDisplayFlag = true

    this.$selectDomesticScDisplayFlag = true

    eventBus.$on('enter-ShopSearch', () => {
      // const startDate = sessionStorage.getItem('searchDate')

      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      const storeId = sessionStorage.getItem('currentStoreId')
      this.loading = true
      store
        .dispatch('app-enterShop/fetchData', {
          params: {
            storeId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data

          const date = dateFormattedJP(new Date())

          this.storeInfoText = `${sessionStorage.getItem('currentBrandName')} ${sessionStorage.getItem('currentStoreName')} ${date}`

          const weatherInfo = userData.value.weatherInfoDto
          let weatherText = `${weatherInfo.description}  ${weatherInfo.max}/${weatherInfo.min}`
          if (isEmpty(weatherInfo.description)) {
            weatherText = ''
          }

          this.weatherInfoText = `${weatherText}`
          this.iconUrl = `http://openweathermap.org/img/w/${weatherInfo.icon}.png`
          this.loading = false

          // 再検索されたら、初期化
          this.tableFooterData = {
            time: '合計',
            column1: '',
            column2: '',
            column3: '',
            column4: '',
            column5: '',
            column6: '',
            column7: '',
            column8: '',
            column9: '',
            column10: '',
            column11: '',
            column12: '',
            column13: '',
            column14: '',
            column15: '',
            column16: '',
            column17: '',
            column18: '',
            column19: '',
            column20: '',
            column21: '',
            column22: '',
            column23: '',
            column24: '',
            column25: '',
            column26: '',
            column27: '',
            column28: '',
            column29: '',
            column30: '',
          }

          let head2Size
          if (userData.value.searchType === 1) {
            head2Size = userData.value.head2List.length * 2
          } else {
            head2Size = userData.value.head2List.length
          }
          this.widthSize = 1 + userData.value.head1List.length + head2Size + userData.value.head3List.length * 2

          this.widthArray = '20,'
          for (let index = 0; index < this.widthSize - 1; index += 1) {
            if (index === this.widthSize - 2) {
              this.widthArray = `${this.widthArray}15`
            } else {
              this.widthArray = `${this.widthArray}15,`
            }
          }

          console.log(this.widthArray)
          userData.value.tableData.forEach(item => {
            if (item.column1 !== undefined) {
              if (isEmpty(this.tableFooterData.column1)) {
                this.tableFooterData.column1 = 0
              }
              this.tableFooterData.column1 += item.column1.facilityValue
            } else {
              this.tableFooterData.column1 = ''
            }
            if (item.column2 !== undefined) {
              if (isEmpty(this.tableFooterData.column2)) {
                this.tableFooterData.column2 = 0
              }
              this.tableFooterData.column2 += item.column2.facilityValue
            } else {
              this.tableFooterData.column2 = ''
            }
            if (item.column3 !== undefined) {
              if (isEmpty(this.tableFooterData.column3)) {
                this.tableFooterData.column3 = 0
              }
              this.tableFooterData.column3 += item.column3.facilityValue
            } else {
              this.tableFooterData.column3 = ''
            }
            if (item.column4 !== undefined) {
              if (isEmpty(this.tableFooterData.column4)) {
                this.tableFooterData.column4 = 0
              }
              this.tableFooterData.column4 += item.column4.facilityValue
            } else {
              this.tableFooterData.column4 = ''
            }
            if (item.column5 !== undefined) {
              if (isEmpty(this.tableFooterData.column5)) {
                this.tableFooterData.column5 = 0
              }
              this.tableFooterData.column5 += item.column5.facilityValue
            } else {
              this.tableFooterData.column5 = ''
            }
            if (item.column6 !== undefined) {
              if (isEmpty(this.tableFooterData.column6)) {
                this.tableFooterData.column6 = 0
              }
              this.tableFooterData.column6 += item.column6.facilityValue
            } else {
              this.tableFooterData.column6 = ''
            }
            if (item.column7 !== undefined) {
              if (isEmpty(this.tableFooterData.column7)) {
                this.tableFooterData.column7 = 0
              }
              this.tableFooterData.column7 += item.column7.facilityValue
            } else {
              this.tableFooterData.column7 = ''
            }
            if (item.column8 !== undefined) {
              if (isEmpty(this.tableFooterData.column8)) {
                this.tableFooterData.column8 = 0
              }
              this.tableFooterData.column8 += item.column8.facilityValue
            } else {
              this.tableFooterData.column8 = ''
            }
            if (item.column9 !== undefined) {
              if (isEmpty(this.tableFooterData.column9)) {
                this.tableFooterData.column9 = 0
              }
              this.tableFooterData.column9 += item.column9.facilityValue
            } else {
              this.tableFooterData.column9 = ''
            }
            if (item.column10 !== undefined) {
              if (isEmpty(this.tableFooterData.column10)) {
                this.tableFooterData.column10 = 0
              }
              this.tableFooterData.column10 += item.column10.facilityValue
            } else {
              this.tableFooterData.column10 = ''
            }
            if (item.column11 !== undefined) {
              if (isEmpty(this.tableFooterData.column11)) {
                this.tableFooterData.column11 = 0
              }
              this.tableFooterData.column11 += item.column11.facilityValue
            } else {
              this.tableFooterData.column11 = ''
            }
            if (item.column12 !== undefined) {
              if (isEmpty(this.tableFooterData.column12)) {
                this.tableFooterData.column12 = 0
              }
              this.tableFooterData.column12 += item.column12.facilityValue
            } else {
              this.tableFooterData.column12 = ''
            }
            if (item.column13 !== undefined) {
              if (isEmpty(this.tableFooterData.column13)) {
                this.tableFooterData.column13 = 0
              }
              this.tableFooterData.column13 += item.column13.facilityValue
            } else {
              this.tableFooterData.column13 = ''
            }
            if (item.column14 !== undefined) {
              if (isEmpty(this.tableFooterData.column14)) {
                this.tableFooterData.column14 = 0
              }
              this.tableFooterData.column14 += item.column14.facilityValue
            } else {
              this.tableFooterData.column14 = ''
            }
            if (item.column15 !== undefined) {
              if (isEmpty(this.tableFooterData.column15)) {
                this.tableFooterData.column15 = 0
              }
              this.tableFooterData.column15 += item.column15.facilityValue
            } else {
              this.tableFooterData.column15 = ''
            }
            if (item.column16 !== undefined) {
              if (isEmpty(this.tableFooterData.column16)) {
                this.tableFooterData.column16 = 0
              }
              this.tableFooterData.column16 += item.column16.facilityValue
            } else {
              this.tableFooterData.column16 = ''
            }
            if (item.column17 !== undefined) {
              if (isEmpty(this.tableFooterData.column17)) {
                this.tableFooterData.column17 = 0
              }
              this.tableFooterData.column17 += item.column17.facilityValue
            } else {
              this.tableFooterData.column17 = ''
            }
            if (item.column18 !== undefined) {
              if (isEmpty(this.tableFooterData.column18)) {
                this.tableFooterData.column18 = 0
              }
              this.tableFooterData.column18 += item.column18.facilityValue
            } else {
              this.tableFooterData.column18 = ''
            }
            if (item.column19 !== undefined) {
              if (isEmpty(this.tableFooterData.column19)) {
                this.tableFooterData.column19 = 0
              }
              this.tableFooterData.column19 += item.column19.facilityValue
            } else {
              this.tableFooterData.column19 = ''
            }
            if (item.column20 !== undefined) {
              if (isEmpty(this.tableFooterData.column20)) {
                this.tableFooterData.column20 = 0
              }
              this.tableFooterData.column20 += item.column20.facilityValue
            } else {
              this.tableFooterData.column20 = ''
            }
            if (item.column21 !== undefined) {
              if (isEmpty(this.tableFooterData.column21)) {
                this.tableFooterData.column21 = 0
              }
              this.tableFooterData.column21 += item.column21.facilityValue
            } else {
              this.tableFooterData.column21 = ''
            }
            if (item.column22 !== undefined) {
              if (isEmpty(this.tableFooterData.column22)) {
                this.tableFooterData.column22 = 0
              }
              this.tableFooterData.column22 += item.column22.facilityValue
            } else {
              this.tableFooterData.column22 = ''
            }
            if (item.column23 !== undefined) {
              if (isEmpty(this.tableFooterData.column23)) {
                this.tableFooterData.column23 = 0
              }
              this.tableFooterData.column23 += item.column23.facilityValue
            } else {
              this.tableFooterData.column23 = ''
            }
            if (item.column24 !== undefined) {
              if (isEmpty(this.tableFooterData.column24)) {
                this.tableFooterData.column24 = 0
              }
              this.tableFooterData.column24 += item.column24.facilityValue
            } else {
              this.tableFooterData.column24 = ''
            }
            if (item.column25 !== undefined) {
              if (isEmpty(this.tableFooterData.column25)) {
                this.tableFooterData.column25 = 0
              }
              this.tableFooterData.column25 += item.column25.facilityValue
            } else {
              this.tableFooterData.column25 = ''
            }
            if (item.column26 !== undefined) {
              if (isEmpty(this.tableFooterData.column26)) {
                this.tableFooterData.column26 = 0
              }
              this.tableFooterData.column26 += item.column26.facilityValue
            } else {
              this.tableFooterData.column26 = ''
            }
            if (item.column27 !== undefined) {
              if (isEmpty(this.tableFooterData.column27)) {
                this.tableFooterData.column27 = 0
              }
              this.tableFooterData.column27 += item.column27.facilityValue
            } else {
              this.tableFooterData.column27 = ''
            }
            if (item.column28 !== undefined) {
              if (isEmpty(this.tableFooterData.column28)) {
                this.tableFooterData.column28 = 0
              }
              this.tableFooterData.column28 += item.column28.facilityValue
            } else {
              this.tableFooterData.column28 = ''
            }
            if (item.column29 !== undefined) {
              if (isEmpty(this.tableFooterData.column29)) {
                this.tableFooterData.column29 = 0
              }
              this.tableFooterData.column29 += item.column29.facilityValue
            } else {
              this.tableFooterData.column29 = ''
            }
            if (item.column30 !== undefined) {
              if (isEmpty(this.tableFooterData.column30)) {
                this.tableFooterData.column30 = 0
              }
              this.tableFooterData.column30 += item.column30.facilityValue
            } else {
              this.tableFooterData.column30 = ''
            }
          })
        })
        .catch(error => {
          // ログイン画面に遷移する
          console.log(error)

          this.$router.push({ name: 'error-login' })
        })
    },

    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]
        const dataArray = ref([])
        element.facilityInfoData.forEach(item => {
          dataArray.value.push(item.facilityValue)
        })
        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: true,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: dataArray.value,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '0%',
          text: '',
        },

        // toolbox: {
        //   bottom: '0',
        //   left: '5%',
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        tooltip: { trigger: 'axis' },
        legend: {
          bottom: '0',
          left: 'center',
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '20%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: [0, 0.01],
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },
    imageClick(facadeCameraInfo) {
      const resolvedRoute = this.$router.resolve({
        name: 'apps-vision',
      })
      sessionStorage.setItem('cameraId', facadeCameraInfo.id)
      window.open(resolvedRoute.href, '_blank')
    },

    getColumnClass(index, column) {
      let css = 'text-right'
      if (this.userData.searchType === 1) {
        return this.getClass(null, index)
      }

      // 先週
      if (this.userData.lastWeekHolidayColumns.indexOf(column) >= 0) {
        css = this.getLastWeekClass(index)
      } else if (this.userData.lastYearSameWeekHolidayColumns.indexOf(column) >= 0) {
        // 昨年同曜日
        css = this.getLastYearSameWeekClass(index)
      } else if (this.userData.lastYearHolidayColumns.indexOf(column) >= 0) {
        // 昨年同日
        css = this.getLastYearSameDateClass(index)
      }

      return css
    },
    getLastWeekClass(index) {
      if (this.userData.searchType === 1) return ''
      let css = 'Normal text-right'
      const time = this.userData.lastWeekDayLegendData[index]
      if (this.userData.lastWeekHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('土') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('日') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getLastYearSameDateClass(index) {
      if (this.userData.searchType === 1) return ''
      let css = 'Normal text-right'
      const time = this.userData.lastYearLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('土') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('日') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getLastYearSameWeekClass(index) {
      if (this.userData.searchType === 1) return ''
      let css = 'Normal text-right'
      const time = this.userData.lastYearSameWeekLegendData[index]
      if (time.indexOf('土') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('日') >= 0) {
        css = 'Sun text-right'
      } else if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      }

      return css
    },

    getClass(time, index) {
      let css = 'text-right'

      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          css = 'text-right'
        } else {
          css = 'Gray text-right'
        }

        return css
      }

      if (time.indexOf('土') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('日') >= 0) {
        css = 'Sun'
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        css = 'Holiday'
      }

      return css
    },

    getColumnColor(time, index, column) {
      let color = 'ffffff'
      if (this.userData.searchType === 1) {
        return this.getColor(null, index)
      }

      // 先週
      if (this.userData.lastWeekHolidayColumns.indexOf(column) >= 0) {
        color = this.getLastWeekColor(index)
      } else if (this.userData.lastYearSameWeekHolidayColumns.indexOf(column) >= 0) {
        // 昨年同曜日
        color = this.getLastYearSameWeekColor(index)
      } else if (this.userData.lastYearHolidayColumns.indexOf(column) >= 0) {
        // 昨年同日
        color = this.getLastYearSameDateColor(index)
      } else {
        color = this.getColor(time, index)
      }

      return color
    },
    getLastWeekColor(index) {
      let color = 'ffffff'
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastWeekDayLegendData[index]
      if (this.userData.lastWeekHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('土') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('日') >= 0) {
        color = 'ffccff'
      }

      return color
    },

    getLastYearSameDateColor(index) {
      if (this.userData.searchType === 1) return 'ffffff'
      let css = 'ffffff'
      const time = this.userData.lastYearLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'FEDCBD'
      } else if (time.indexOf('土') >= 0) {
        css = 'ccffff'
      } else if (time.indexOf('日') >= 0) {
        css = 'ffccff'
      }

      return css
    },

    getLastYearSameWeekColor(index) {
      if (this.userData.searchType === 1) return 'ffffff'
      let css = 'ffffff'
      const time = this.userData.lastYearSameWeekLegendData[index]
      if (time.indexOf('土') >= 0) {
        css = 'ccffff'
      } else if (time.indexOf('日') >= 0) {
        css = 'ffccff'
      } else if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'FEDCBD'
      }

      return css
    },
    getColor(time, index) {
      let color = 'ffffff'
      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          color = 'ffffff'
        } else {
          color = 'c0c0c0'
        }
      } else if (time.indexOf('土') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('日') >= 0) {
        color = 'ffccff'
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else {
        color = ''
      }

      return color
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, enterShopStoreModule)
    }
    const startDate = sessionStorage.getItem('startDate')
    if (isEmpty(startDate)) {
      const startDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      sessionStorage.setItem('startDate', startDateFormatted)
      sessionStorage.setItem('endDate', endDateFormatted)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const searchDate = sessionStorage.getItem('searchDate')
    if (isEmpty(searchDate)) {
      const startDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')
      sessionStorage.setItem('searchDate', startDateFormatted)
    }

    const currentUrl = window.location.href

    const url = currentUrl.substr(0, currentUrl.indexOf('#'))

    const isKeyExists = obj => {
      if (obj === undefined) {
        return false
      }

      return true
    }

    const storeInfoText = ref()
    const weatherInfoText = ref()
    const iconUrl = ref()

    const widthSize = ref()

    const widthArray = ref()
    const tableFooterData = ref({
      time: '合計',
      column1: '',
      column2: '',
      column3: '',
      column4: '',
      column5: '',
      column6: '',
      column7: '',
      column8: '',
      column9: '',
      column10: '',
      column11: '',
      column12: '',
      column13: '',
      column14: '',
      column15: '',
      column16: '',
      column17: '',
      column18: '',
      column19: '',
      column20: '',
    })

    return {
      widthArray,
      widthSize,
      userData,
      url,
      isKeyExists,
      storeInfoText,
      weatherInfoText,
      dateFormattedJP,
      loading: false,
      isEmpty,
      tableFooterData,
      iconUrl,
      currentStoreId: sessionStorage.getItem('currentStoreId'),
      icons: {
        mdiDownloadOutline,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000 !important;
  padding: 0px 1px !important;
  font-size: 12px !important;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  white-space: nowrap;
}
.v-data-table th {
  background-color: #ffccff !important;
}

.v-data-table tbody .Normal {
  background-color: #fff;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}

.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}

/* table thead tr th {
  background: #ffccff;
} */
</style>
